import Cookie from 'js.cookie';
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    $('img').parent('a').addClass('no-external-link');
    $('i').parent('a').addClass('no-external-link');

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });
  
    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });
  
    //sidenav dropdown on click
    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });
    
    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    $('#review-us a.nav-link').click(function(){
      $('#review-us nav').hide();
    });
  },
};
